import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { Brand, IconButton, CaMonogramIcon, SpinnerIcon, SettingsIcon, MenuList, MenuHeading, Menu as Menu$1, ChevronDownIcon, VisuallyHidden, MenuItem, ExternalLinkIcon } from '@kaizen/components';
import { SkipLink } from '../SkipLink/SkipLink.mjs';
import { UtilityItem } from '../UtilityItems/UtilityItems.mjs';
import { ampli } from '../../ampli/index.mjs';
import { getHelpItem } from '../helpers/getHelpItem.mjs';
import { getLinkPropsFromFirstNavItem, isMenu, getPathnameOrHref, isMenuItemGroup, groupMenuItems, menuHasActiveChildItem, idIsActive } from '../helpers/uiHelpers.mjs';
import { httpRequest } from '../helpers/httpRequestHelper.mjs';
import { useMainContent } from '../hooks/useMainContent.mjs';
import ProfileMenu from '../ProfileMenu/ProfileMenu.mjs';
import React from 'react';
import SuperuserMenu from '../SuperuserMenu/SuperuserMenu.mjs';
import cx from 'classnames';
import useResponsiveVariant from '../hooks/useResponsiveVariant.mjs';
const Link = ({
  activeRouteId,
  isMenuItem,
  link,
  onClick,
  groupTitle
}) => {
  const isActive = idIsActive(link.id, activeRouteId);
  const onClickWithTracking = event => {
    onClick && onClick(event);
    ampli.navigationBarClicked({
      "Menu item": link.id,
      "Menu header": groupTitle
    });
  };
  return isMenuItem ? jsx(MenuItem, {
    onClick: onClickWithTracking,
    label: link.title,
    href: getPathnameOrHref(link),
    isActive: isActive,
    target: link.isExternal ? "_blank" : undefined,
    icon: link.isExternal ? jsx(ExternalLinkIcon, {
      role: "presentation"
    }) : undefined
  }, link.id) : jsx("a", {
    className: cx(
    // need class
    "navigationItem", "un-h-72 un-relative un-flex un-items-center un-justify-center un-py-0 un-px-16 un-font-family-heading un-font-weight-paragraph-bold un-text-heading-4 un-leading-heading-4 un-tracking-letter-spacing-normal un-bg-transparent un-border-none [text-decoration:none]",
    //.tier4/5
    "[.tier4_&]:un-text-heading-5 [.tier4_&]:un-py-0 [.tier4_&]:un-px-12", "[.tier5_&]:un-text-heading-6 [.tier5_&]:un-py-0 [.tier5_&]:un-px-12",
    // hover
    "hover:[text-decoration:none] hover:un-cursor-pointer",
    // focus
    "focus:un-outline-none",
    // active
    isActive ? "un-text-white" : "un-text-white/[0.8]", isActive && "un-text-white before:un-content-[''] before:un-block before:un-h-[5px] before:un-absolute before:un-top-0 before:un-inset-x-6 [transition:transform_cubic-bezier(0.55_0.085_0.68_0.53)_150ms;] before:un-rounded-[0_0_7px_7px]",
    // active default education admin
    isActive && "before:[.default_&]:un-bg-white before:[.education_&]:un-bg-blue-500 before:[.admin_&]:un-bg-blue-500",
    // high contrast
    isActive && "forced-colors:un-border-solid forced-colors:un-border-b-4",
    // admin
    "[.admin_&]:un-text-purple-800/[0.7]",
    // education
    "[.education_&]:un-text-purple-800/[0.7]",
    // hover focus
    "hover:[.default_&]:un-text-white focus:[.default_&]:un-text-white", "hover:[.admin_&]:un-text-blue-500 focus:[.admin_&]:un-text-blue-500", "hover:[.education_&]:un-text-blue-500 focus:[.education_&]:un-text-blue-500"),
    href: getPathnameOrHref(link),
    "aria-current": isActive ? "page" : undefined,
    onClick: event => {
      onClickWithTracking(event);
      if (link.method && !event.defaultPrevented) {
        event.preventDefault();
        httpRequest(link, {});
      }
    },
    children: jsx("span", {
      className: cx("un-relative un-flex un-items-center",
      // hover focus before
      "[.navigationItem:hover_&]:before:un-bg-white/[0.1] [.navigationItem:hover_&]:before:un-content-[''] [.navigationItem:hover_&]:before:-un-inset-x-16 [.navigationItem:hover_&]:before:un-inset-y-[calc(0.75rem*-1)] [.navigationItem:hover_&]:before:un-absolute [.navigationItem:hover_&]:before:un-rounded-[7px]", "[.navigationItem:focus_&]:before:un-bg-white/[0.1] [.navigationItem:focus_&]:before:un-content-[''] [.navigationItem:focus_&]:before:-un-inset-x-16 [.navigationItem:focus_&]:before:un-inset-y-[calc(0.75rem*-1)] [.navigationItem:focus_&]:before:un-absolute [.navigationItem:focus_&]:before:un-rounded-[7px]",
      // tier4/5
      "[.tier4_.navigationItem:hover_&]:before:un-inset-x-[calc(0.75rem*-1)]", "[.tier5_.navigationItem:hover_&]:before:un-inset-x-[calc(0.75rem*-1)]", "[.tier4_.navigationItem:focus_&]:before:un-inset-x-[calc(0.75rem*-1)]", "[.tier5_.navigationItem:focus_&]:before:un-inset-x-[calc(0.75rem*-1)]",
      // admin
      "[.admin_.navigationItem:hover_&]:before:un-bg-blue-100 [.admin_.navigationItem:hover_&]:before:un-content-[''] [.admin_.navigationItem:hover_&]:before:-un-inset-x-16 [.admin_.navigationItem:hover_&]:before:un-inset-y-[calc(0.75rem*-1)] [.admin_.navigationItem:hover_&]:before:un-absolute [.admin_.navigationItem:hover_&]:before:un-rounded-[7px]", "[.admin_.navigationItem:focus_&]:before:un-bg-blue-100 [.admin_.navigationItem:focus_&]:before:un-content-[''] [.admin_.navigationItem:focus_&]:before:-un-inset-x-16 [.admin_.navigationItem:focus_&]:before:un-inset-y-[calc(0.75rem*-1)] [.admin_.navigationItem:focus_&]:before:un-absolute [.admin_.navigationItem:focus_&]:before:un-rounded-[7px]",
      // education
      "[.education_.navigationItem:hover_&]:before:un-bg-white/[0.85] [.education_.navigationItem:hover_&]:before:un-content-[''] [.education_.navigationItem:hover_&]:before:-un-inset-x-16 [.education_.navigationItem:hover_&]:before:un-inset-y-[calc(0.75rem*-1)] [.education_.navigationItem:hover_&]:before:un-absolute [.education_.navigationItem:hover_&]:before:un-rounded-[7px]", "[.education_.navigationItem:focus_&]:before:un-bg-white/[0.85] [.education_.navigationItem:focus_&]:before:un-content-[''] [.education_.navigationItem:focus_&]:before:-un-inset-x-16 [.education_.navigationItem:focus_&]:before:un-inset-y-[calc(0.75rem*-1)] [.education_.navigationItem:focus_&]:before:un-absolute [.education_.navigationItem:focus_&]:before:un-rounded-[7px]",
      // focus ring
      "[.navigationItem:focus-visible_&]:after:un-content-[''] [.navigationItem:focus-visible_&]:after:-un-inset-x-16 [.navigationItem:focus-visible_&]:after:un-inset-y-[calc(0.75rem*-1)] [.navigationItem:focus-visible_&]:after:un-absolute [.navigationItem:focus-visible_&]:after:un-rounded-focus-ring [.navigationItem:focus-visible_&]:after:un-m-[calc(calc((calc(2px*2))+1px)*-1)] [.navigationItem:focus-visible_&]:after:un-border [.navigationItem:focus-visible_&]:after:un-border-solid [.navigationItem:focus-visible_&]:after:un-border-blue-200", "[.education_.navigationItem:focus-visible_&]:after:un-border-blue-500"),
      children: jsx("span", {
        className: cx("un-translate-x-0",
        // education
        isActive && "[.education_&]:un-text-blue-500",
        // admin
        isActive && "[.admin_&]:un-text-blue-500"),
        children: link.title
      })
    })
  });
};
const Menu = ({
  menu,
  link,
  activeRouteId
}) => {
  const containsItemGroups = menu.menuItems.find(item => isMenuItemGroup(item));
  let menuContent;
  if (containsItemGroups) {
    menuContent = groupMenuItems(menu.menuItems).map(item => {
      return jsx(MenuList, {
        heading: (item === null || item === void 0 ? void 0 : item.groupTitle) ? jsx(MenuHeading, {
          tag: "h2",
          children: item === null || item === void 0 ? void 0 : item.groupTitle
        }) : jsx(Fragment, {}),
        children: item.menuItems.map(link)
      }, item.id);
    });
  } else {
    menuContent = jsx(MenuList, {
      children: menu.menuItems.map(item => {
        if (isMenuItemGroup(item)) {
          // Not possible because of the `containsItemGroups` check above, but TS can't work that out
          return;
        }
        return jsx(React.Fragment, {
          children: link(item)
        }, item.id);
      })
    });
  }
  const hasActiveChild = menuHasActiveChildItem(menu, activeRouteId);
  return jsx(Menu$1, {
    button: jsxs("button", {
      className: cx(
      // need class
      "navigationItem", "un-h-72 un-relative un-flex un-items-center un-justify-center un-py-0 un-px-16 un-font-family-heading un-font-weight-paragraph-bold un-text-heading-4 un-leading-heading-4 un-tracking-letter-spacing-normal un-bg-transparent un-border-none [text-decoration:none]",
      // hasDropdown
      "-un-mb-[11px]",
      //.tier4/5
      "[.tier4_&]:un-text-heading-5 [.tier4_&]:un-py-0 [.tier4_&]:un-px-12", "[.tier5_&]:un-text-heading-6 [.tier5_&]:un-py-0 [.tier5_&]:un-px-12",
      // hover
      "hover:[text-decoration:none] hover:un-cursor-pointer",
      // focus
      "focus:un-outline-none",
      // active
      hasActiveChild ? "un-text-white" : "un-text-white/[0.8]", hasActiveChild && "un-text-white before:un-content-[''] before:un-block before:un-h-[5px] before:un-absolute before:un-top-0 before:un-inset-x-6 [transition:transform_cubic-bezier(0.55_0.085_0.68_0.53)_150ms;] before:un-rounded-[0_0_7px_7px]",
      // active default education admin
      hasActiveChild && "before:[.default_&]:un-bg-white before:[.education_&]:un-bg-blue-500 before:[.admin_&]:un-bg-blue-500",
      // high contrast
      hasActiveChild && "forced-colors:un-border-solid forced-colors:un-border-b-4",
      // admin
      "[.admin_&]:un-text-purple-800/[0.7]",
      // education
      "[.education_&]:un-text-purple-800/[0.7]",
      // hover focus
      "hover:[.default_&]:un-text-white focus:[.default_&]:un-text-white", "hover:[.admin_&]:un-text-blue-500 focus:[.admin_&]:un-text-blue-500", "hover:[.education_&]:un-text-blue-500 focus:[.education_&]:un-text-blue-500"),
      children: [jsxs("span", {
        className: cx("un-relative un-flex un-items-center",
        // hover focus before
        "[.navigationItem:hover_&]:before:un-bg-white/[0.1] [.navigationItem:hover_&]:before:un-content-[''] [.navigationItem:hover_&]:before:-un-inset-x-16 [.navigationItem:hover_&]:before:un-inset-y-[calc(0.75rem*-1)] [.navigationItem:hover_&]:before:un-absolute [.navigationItem:hover_&]:before:un-rounded-[7px]", "[.navigationItem:focus_&]:before:un-bg-white/[0.1] [.navigationItem:focus_&]:before:un-content-[''] [.navigationItem:focus_&]:before:-un-inset-x-16 [.navigationItem:focus_&]:before:un-inset-y-[calc(0.75rem*-1)] [.navigationItem:focus_&]:before:un-absolute [.navigationItem:focus_&]:before:un-rounded-[7px]",
        // tier4/5
        "[.tier4_.navigationItem:hover_&]:before:un-inset-x-[calc(0.75rem*-1)]", "[.tier5_.navigationItem:hover_&]:before:un-inset-x-[calc(0.75rem*-1)]", "[.tier4_.navigationItem:focus_&]:before:un-inset-x-[calc(0.75rem*-1)]", "[.tier5_.navigationItem:focus_&]:before:un-inset-x-[calc(0.75rem*-1)]",
        // admin
        "[.admin_.navigationItem:hover_&]:before:un-bg-blue-100 [.admin_.navigationItem:hover_&]:before:un-content-[''] [.admin_.navigationItem:hover_&]:before:-un-inset-x-16 [.admin_.navigationItem:hover_&]:before:un-inset-y-[calc(0.75rem*-1)] [.admin_.navigationItem:hover_&]:before:un-absolute [.admin_.navigationItem:hover_&]:before:un-rounded-[7px]", "[.admin_.navigationItem:focus_&]:before:un-bg-blue-100 [.admin_.navigationItem:focus_&]:before:un-content-[''] [.admin_.navigationItem:focus_&]:before:-un-inset-x-16 [.admin_.navigationItem:focus_&]:before:un-inset-y-[calc(0.75rem*-1)] [.admin_.navigationItem:focus_&]:before:un-absolute [.admin_.navigationItem:focus_&]:before:un-rounded-[7px]",
        // education
        "[.education_.navigationItem:hover_&]:before:un-bg-white/[0.85] [.education_.navigationItem:hover_&]:before:un-content-[''] [.education_.navigationItem:hover_&]:before:-un-inset-x-16 [.education_.navigationItem:hover_&]:before:un-inset-y-[calc(0.75rem*-1)] [.education_.navigationItem:hover_&]:before:un-absolute [.education_.navigationItem:hover_&]:before:un-rounded-[7px]", "[.education_.navigationItem:focus_&]:before:un-bg-white/[0.85] [.education_.navigationItem:focus_&]:before:un-content-[''] [.education_.navigationItem:focus_&]:before:-un-inset-x-16 [.education_.navigationItem:focus_&]:before:un-inset-y-[calc(0.75rem*-1)] [.education_.navigationItem:focus_&]:before:un-absolute [.education_.navigationItem:focus_&]:before:un-rounded-[7px]",
        // focus ring
        "[.navigationItem:focus-visible_&]:after:un-content-[''] [.navigationItem:focus-visible_&]:after:-un-inset-x-16 [.navigationItem:focus-visible_&]:after:un-inset-y-[calc(0.75rem*-1)] [.navigationItem:focus-visible_&]:after:un-absolute [.navigationItem:focus-visible_&]:after:un-rounded-focus-ring [.navigationItem:focus-visible_&]:after:un-m-[calc(calc((calc(2px*2))+1px)*-1)] [.navigationItem:focus-visible_&]:after:un-border [.navigationItem:focus-visible_&]:after:un-border-solid [.navigationItem:focus-visible_&]:after:un-border-blue-200", "[.education_.navigationItem:focus-visible_&]:after:un-border-blue-500"),
        children: [jsx("span", {
          className: cx("un-translate-x-0",
          // education
          hasActiveChild && "[.education_&]:un-text-blue-500",
          // admin
          hasActiveChild && "[.admin_&]:un-text-blue-500"),
          children: menu.title
        }), jsx("span", {
          className: cx("un-translate-x-6 un-flex",
          // rtl
          "rtl:-un-translate-x-6",
          // education
          "[.active_.education_&]:un-text-blue-500",
          // admin
          "[.active_.admin_&]:un-text-blue-500"),
          children: jsx(ChevronDownIcon, {
            role: "presentation"
          })
        })]
      }), hasActiveChild && jsx(VisuallyHidden, {
        children: "current section"
      })]
    }),
    children: menuContent
  });
};
const RESPONSIVE_VARIANTS = ["tier5", "tier4", "tier3_noLogoOrUtilityText", "tier2_noLogoText", "tier1_fullSize"];
const NavigationBar = props => {
  const {
    navbarRef,
    logoAndNavItemsContainerRef,
    currentResponsiveVariant,
    isAbove
  } = useResponsiveVariant(props.textDirection, props.loading, ...RESPONSIVE_VARIANTS);
  const {
    navigationData,
    textDirection,
    loading,
    onNavigationLinkClick,
    extraUtility,
    variant = "default",
    skipLinkTargetId,
    onHelpClick
  } = props;
  const {
    navItems,
    helpItem,
    helpLabel
  } = navigationData;
  const homeLink = getLinkPropsFromFirstNavItem(navItems);
  const helpItemData = getHelpItem(helpItem, helpLabel);
  const HelpIcon = helpItemData.icon;
  const [mainNavRef, idOfMainContent] = useMainContent();
  return jsxs("div", {
    ref: mainNavRef,
    className: "un-h-72 un-w-full",
    children: [jsx(SkipLink, {
      label: "Skip to content",
      skipTo: skipLinkTargetId || idOfMainContent
    }), jsx("nav", {
      dir: textDirection,
      ref: navbarRef,
      className: cx("un-w-full un-fixed un-flex un-items-center un-h-72 un-z-[1030] un-antialiased",
      // "default"
      (variant === "default" || variant === "local") && "un-bg-purple-600",
      // "education"
      variant === "education" && "un-bg-blue-100",
      // "admin"
      variant === "admin" && "un-bg-white un-text-purple-800/[0.7]", "un-flex-wrap",
      // needed js as classes
      currentResponsiveVariant ? currentResponsiveVariant : null, variant),
      children: jsxs("div", {
        className: "un-flex un-flex-[0_1_100%] un-z-[1030]",
        children: [jsxs("div", {
          className: "un-flex un-flex-shrink-0 un-h-72 un-mr-auto un-ml-0 rtl:un-mr-0 rtl:un-ml-auto",
          ref: logoAndNavItemsContainerRef,
          children: [currentResponsiveVariant === "tier1_fullSize" ? jsx("a", {
            href: homeLink.href,
            className: cx("caLogoContainer", "un-flex un-justify-center un-items-center",
            // local
            "[.local_&]:un-text-white",
            // default
            "[.default_&]:un-text-white",
            // admin
            "[.admin_&]:un-text-purple-800",
            // education
            "[.education_&]:un-text-purple-800",
            // base
            "un-relative un-w-[calc(1.5rem*5)] un-my-0 un-mx-24",
            // focus
            "focus:un-outline-none"),
            children: jsx("span", {
              className: cx("un-relative un-flex un-items-center",
              // focus ring
              "[.caLogoContainer:focus-visible_&]:after:un-content-[''] [.caLogoContainer:focus-visible_&]:after:-un-inset-x-16 [.caLogoContainer:focus-visible_&]:after:un-inset-y-[calc(0.75rem*-1)] [.caLogoContainer:focus-visible_&]:after:un-absolute [.caLogoContainer:focus-visible_&]:after:un-rounded-focus-ring [.caLogoContainer:focus-visible_&]:after:un-m-[calc(calc((calc(2px*2))+1px)*-1)] [.caLogoContainer:focus-visible_&]:after:un-border [.caLogoContainer:focus-visible_&]:after:un-border-solid [.caLogoContainer:focus-visible_&]:after:un-border-blue-200", "[.education_.caLogoContainer:focus-visible_&]:after:un-border-blue-500"),
              children: jsx(Brand, {
                reversed: variant === "default" || variant === "local",
                variant: "logo-horizontal",
                alt: `Culture Amp ${homeLink.title}`
              })
            })
          }) : jsx("div", {
            className: cx("un-w-[3.75rem] un-flex un-justify-center un-items-center",
            // local
            "[.local_&]:un-text-white",
            // default
            "[.default_&]:un-text-white",
            // admin
            "[.admin_&]:un-text-purple-800",
            // education
            "[.education_&]:un-text-purple-800"),
            children: jsx(IconButton, {
              reversed: variant === "default" || variant === "local",
              href: homeLink.href,
              icon: jsx(CaMonogramIcon, {
                role: "presentation"
              }),
              label: `Culture Amp ${homeLink.title}`
            })
          }), jsx("ul", {
            className: "un-flex un-flex-shrink-0 un-list-none un-m-0 un-p-0",
            children: navigationData.navItems.map(item => {
              if (isMenu(item)) {
                return jsx("li", {
                  children: jsx(Menu, {
                    menu: item,
                    activeRouteId: props.activeRouteId,
                    link: link => jsx(Link, {
                      link: link,
                      isMenuItem: true,
                      onClick: onNavigationLinkClick,
                      activeRouteId: props.activeRouteId,
                      groupTitle: item.title
                    }, link.id)
                  })
                }, item.id);
              }
              return jsx("li", {
                children: jsx(Link, {
                  isMenuItem: false,
                  link: item,
                  onClick: onNavigationLinkClick,
                  activeRouteId: props.activeRouteId
                })
              }, item.id);
            })
          })]
        }), jsxs("div", {
          className: "un-flex un-items-center un-flex-shrink-0 un-h-72 un-pl-0 un-pr-[0.75rem] rtl:un-pl-[0.75rem] rtl:un-pr-0",
          children: [extraUtility, loading && jsx("div", {
            className: cx("un-w-[3.75rem] un-flex un-justify-center un-items-center",
            // local
            "[.local_&]:un-text-white",
            // default
            "[.default_&]:un-text-white",
            // admin
            "[.admin_&]:un-text-purple-800",
            // education
            "[.education_&]:un-text-purple-800"),
            children: jsx(SpinnerIcon, {
              role: "img",
              "aria-label": "Loading"
            })
          }), navigationData.settingsItem && jsx(UtilityItem, {
            id: navigationData.settingsItem.id,
            Icon: SettingsIcon,
            showLabel: isAbove("tier3_noLogoOrUtilityText"),
            title: navigationData.settingsItem.title,
            onClick: onNavigationLinkClick,
            href: getPathnameOrHref(navigationData.settingsItem),
            variant: variant
          }), onHelpClick && jsx(UtilityItem, {
            Icon: HelpIcon,
            showLabel: isAbove("tier3_noLogoOrUtilityText"),
            title: helpItemData.label,
            onClick: () => {
              onHelpClick();
              ampli.helpToolInitiated();
            },
            variant: variant
          }), navigationData.superuserItem && jsx("div", {
            className: "un-flex un-items-center un-justify-center",
            "data-automation-id": "SuperuserMenu",
            children: jsx(SuperuserMenu, {
              onLinkClick: onNavigationLinkClick,
              menuItems: navigationData.superuserItem.menuItems,
              variant: variant
            })
          }), navigationData.profileItem && jsx("div", {
            className: "un-flex un-items-center un-justify-center",
            "data-automation-id": "ProfileMenu",
            children: jsx(ProfileMenu, {
              ...navigationData.profileItem,
              onLinkClick: onNavigationLinkClick,
              variant: variant
            })
          })]
        })]
      })
    })]
  });
};
export { NavigationBar };
